import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import pagesHeadersArray from '../../datas/pagesHeadersArray.json'
import PagesHeaders from '../../components/PagesHeaders'
import Footer from '../../components/Footer'

import './m-galerie-card.css'
import './d-galerie-card.css'


const GalerieCard = () => {

    const getGalerieListArray = localStorage.getItem('galerieListArray')
    const galerieListArray = JSON.parse(getGalerieListArray) || []

    localStorage.removeItem('token')
    
    useEffect(() => {
        document.documentElement.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    const GaleriesHeader = pagesHeadersArray.find(el => el.title === "6")
    const GaleriesHeaderArray = []
    GaleriesHeaderArray.push(GaleriesHeader)

    return (
        <>
            <section>
                <Link to='/'>
                    <img   
                    className='navbar__logo-bcra logo-margin-bottom' 
                    src={`${process.env.PUBLIC_URL}/assets/pictures/logo-bcra.png`}
                    width='1788px'
                    height='1092px'
                    fetchpriority='high'
                    alt='bcra-icon'   
                    />
                </Link>
                {GaleriesHeaderArray.map((item, index) => ( 
                    <PagesHeaders 
                        key={index}
                        titleCol1={item.titleCol1}
                        subTitle1={item.subTitle1}
                        subTitle2={item.subTitle2}
                        text1={
                            <div className='galerie-card'> 
                                {galerieListArray.map((items, id) => (
                                    <div className='galerie-card__chantier' key={id}>
                                        <Link to={`/Card/${items.id}`}>
                                            <img 
                                                className='galerie-card__chantier__picture' 
                                                src={items.cover}
                                                width='1024px'
                                                height='1024px'
                                                fetchpriority='high' 
                                                alt="chantier" 
                                            />
                                            <div className='galerie-card__chantier__background'></div>
                                            <p className='galerie-card__chantier__title'>{items.title}</p>
                                        </Link>
                                    </div>
                                ))}
                            </div> 
                        }
                    />
                ))}
            </section>
            <Footer />
        </>
    )
}

export default GalerieCard