import { Link } from 'react-router-dom'
import {fab, faFacebook, faLinkedin, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// styles
import './m-footer.css'
import './d-footer.css'

const Footer = () => {

  return (
    <section className='footer'>
      <h1>Suivez-nous</h1>
      <div className='footer__link'>
        <Link to='/'>
          <FontAwesomeIcon className='footer__link__icon icon-fb' icon={(fab, faFacebook)} />
        </Link>
        <Link to='/'>
          <FontAwesomeIcon className='footer__link__icon icon-lk' icon={(fab, faLinkedin)} />
        </Link>
        <Link to='/'>
          <FontAwesomeIcon className='footer__link__icon icon-gh' icon={(fab, faInstagram)} />
        </Link>
        <Link to='/'>
          <FontAwesomeIcon className='footer__link__icon icon-yt' icon={(fab, faYoutube)} />
        </Link>
      </div>
      <Link to='https://www.bcra-groupe.fr/'>
        <img 
            className='footer__logo-bcra' 
            src={`${process.env.PUBLIC_URL}/assets/brands/2biconw.png`}
            width='1788px'
            height='1092px'
            fetchpriority='high'  
            alt='logo bcra'
        />
      </Link>
      <div className='footer__logo-labels'>
        <img 
            className='footer__logo-labels__decennal labels' 
            src={`${process.env.PUBLIC_URL}/assets/pictures/label-decennal.png`}
            width='300px'
            height='336px'
            loading='lazy' 
            alt='logo décennal' 
        />
        <img 
            className='footer__logo-labels__qualibat labels' 
            src={`${process.env.PUBLIC_URL}/assets/pictures/label-qualibat.png`}
            width='300px'
            height='336px'
            loading='lazy' 
            alt='logo qualibat' 
        />
      </div>
      <p className='footer__logo-info'>
        BCRA
        <br/>
        RCS 853 722 262 Carcassonne
        <br/>
        Tél : 06 68 07 19 49
        <br/>
        https://www.bcra-groupe.fr
        <br/>
        <span>
          <a 
            href='mailto:bourrel.romain@orange.fr' 
            className='mail-link'
          >
              bourrel.romain@orange.fr
          </a>
        </span>
        <br/>
        1, Place de l'Abreuvoir - 11400 Mireval-Lauragais
      </p>
      <div className='footer__copyright'>
          <p>
              © BCRA - Tous droits réservés - <Link className='footer__copyright__link' to='https://www.bcra-groupe.fr/CGU'>Mentions légales</Link> - 2023 - conception web <Link className='footer__copyright__link' to='https://www.vowd.fr'>VOWD.fr</Link>
          </p>
      </div>	
    </section>
  )
}

export default Footer