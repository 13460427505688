// styles
import './m-background.css'
import './d-background.css'

const Background = () => {

	return (
		<>
			<div className='background'>
				<img className='house' 
					src={`${process.env.PUBLIC_URL}/assets/pictures/background1.jpg`}
					width='1500px'
					height='862px'
					fetchpriority='high' 
					alt='tractopelle' 
				/>
			</div>
		</>
	)
}
 
export default Background