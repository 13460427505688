import { useState, useEffect } from 'react'
import { gsap } from 'gsap'
import Slogan from '../Slogan'

// styles
import './m-banner.css'
import './d-banner.css'

const Banner = () => {

	const [currentState, setCurrentState] = useState(0)

	useEffect(()=> {
		const timer = setTimeout(() => {
			if (currentState === 4) {
				setCurrentState(0)
			}
			else {
				setCurrentState(currentState +1)
			}
		}, 5000)
		return () => clearTimeout(timer)
	}, [currentState] )

	const slideArray1 = [
		{	
			"img": `${process.env.PUBLIC_URL}/assets/pictures/img0-1.jpg`
		},
		{	
			"img": `${process.env.PUBLIC_URL}/assets/pictures/img1-1.jpg`
		},
		{
			"img": `${process.env.PUBLIC_URL}/assets/pictures/img2-1.jpg`
		},
		{
			"img": `${process.env.PUBLIC_URL}/assets/pictures/img3-1.jpg`
		},
		{
			"img": `${process.env.PUBLIC_URL}/assets/pictures/img4-1.jpg`
		}
	]

	const slideArray2 = [
		{	
			"img": `${process.env.PUBLIC_URL}/assets/pictures/img0-2.jpg`
		},
		{	
			"img": `${process.env.PUBLIC_URL}/assets/pictures/img1-2.jpg`
		},
		{
			"img": `${process.env.PUBLIC_URL}/assets/pictures/img2-2.jpg`
		},
		{
			"img": `${process.env.PUBLIC_URL}/assets/pictures/img3-2.jpg`
		},
		{
			"img": `${process.env.PUBLIC_URL}/assets/pictures/img4-2.jpg`
		}
	]

	const onEnter = () => {
        gsap.to('#bannerPubSection', {
            transform: "translateX(0)",
        })
    }

    const onLeave = () => {
        gsap.to('#bannerPubSection', {
            transform: "translateX(-150vw)",
        })
    }

	return (
		<>
			<section 
				id='banner' 
				className='banner'
				onMouseEnter={onEnter}
				onMouseLeave={onLeave}
			>
				<div> 
					{slideArray1.map((items, index) => (
						<div key={index}>
							<img className={index === currentState ? 
								'banner__picture1 banner__picture--in' : 
								'banner__picture1 banner__picture--out'
							}
							src={items.img} 
							width='720px'
							height='1342px'
							fetchpriority='high' 
							alt="photos d'ouvriers" />
						</div>
					))}
					{slideArray2.map((items, index) => (
						<div key={index}>
							<img className={index === currentState ? 
								'banner__picture2 banner__picture--in' : 
								'banner__picture2 banner__picture--out'
							}
							src={items.img} 
							width='1500px'
							height='1069px'
							fetchpriority='high'
							alt="photos d'ouvriers" />
						</div>
					))}
				</div>	
				<div id='bannerPubSection' className='banner__pub-section'>
					<Slogan />
				</div>
			</section>
		</>
	)
}
 
export default Banner