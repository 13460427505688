import { useParams, useNavigate, Navigate, Link as Home } from 'react-router-dom'
import { useState, useEffect } from 'react'
import pagesHeadersArray from '../../datas/pagesHeadersArray.json'
import PagesHeaders from '../PagesHeaders'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Footer from '../../components/Footer'

// styles
import './m-card.css'
import './d-card.css'

const Card = () => {

  localStorage.removeItem('token')
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const getGalerieListArray = localStorage.getItem('galerieListArray')
  const galerieListArray = JSON.parse(getGalerieListArray) || []

  const GaleriesHeader = pagesHeadersArray.find(el => el.title === "7")
  const GaleriesHeaderArray = []
  GaleriesHeaderArray.push(GaleriesHeader)

  const navigate = useNavigate()
  const backToSite1 = () => navigate('/GalerieCard')
  
  // Récupération de la fiche correspondante
  const id = useParams()
  const galerieCard = galerieListArray.find(galerie => galerie.id === id.id)

  let picsLength = galerieCard.pictures.length // Définition de la longueur de l'objet Carrousel

  const [currentPic, setCurrentPic] = useState(0) // Définition du state

  // Définition de la fonction pour aller à l'image suivante du carrousel (+1 à currentPic)
  const nextPic = () => {
    setCurrentPic(currentPic === picsLength - 1 ? 0 : currentPic + 1);
  }

  // Définition de la fonction pour aller à l'image précédente du carrousel (-1 à currentPic)
  const prevPic = () => {
    setCurrentPic(currentPic === 0 ? picsLength - 1 : currentPic - 1);
  }

  return galerieCard ? (
    <>
      <section id='galeries' className='galeries'>
        <FontAwesomeIcon
          onClick={backToSite1}
          className="arrow-left arrow-position"
          icon={faArrowLeft}
        />
        <Home to='https://www.bcra-groupe.fr'>
          <img   
            className='navbar__logo-bcra logo-margin-bottom' 
            src={`${process.env.PUBLIC_URL}/assets/pictures/logo-bcra.png`}
            width='1788px'
            height='1092px'
            fetchpriority='high'
            alt='bcra-icon'   
          />
        </Home> 
        {GaleriesHeaderArray.map((item, index) => ( 
          <PagesHeaders 
            key={index}
            titleCol1={
              <p>{galerieCard.description}</p>
            }
            subTitle1={item.subTitle1}
            subTitle2={item.subTitle2}
            text1={
              <div className='carrousel'>
                {galerieCard.pictures.map((pic, index) => {
                  return (
                    <div
                      // On ajoute l'index à la div et les class active/inactive pour afficher/cacher 
                      key={index}
                      className={
                      index === currentPic ?
                        'carrousel carrousel__pictures__active':
                        'carrousel carrousel__pictures__inactive'
                      }
                    >
                      {/* Si l'index est égal à currentPic, alors on ajoute l'image au carrousel*/}
                      {index === currentPic && (
                          <img 
                            src={pic} 
                            width='1024px'
                            height='1024px'
                            fetchpriority='high'
                            alt='photos de chantiers en travaux' 
                            className='carrousel__pictures carrousel__pictures__current' 
                          />
                        )
                      }
                    </div>
                  )
                })}

                {/* affiche les boutons next/previous et du compteur si il y a plus d'un élément dans le carrousel */}
                {picsLength > 1 ? (
                  <>
                    <button className='carrousel__button carrousel__button__left' onClick={prevPic}>
                      <img 
                        src={`${process.env.PUBLIC_URL}/assets/icons/arrow.png`} 
                        width='317px'
                        height='188px'
                        loading='lazy'
                        alt='flèche vers la gauche' 
                        className='carrousel__button__arrow carrousel__button__arrow__left' 
                      />
                    </button>
                    <button className='carrousel__button carrousel__button__right' onClick={nextPic}>
                      <img 
                        src={`${process.env.PUBLIC_URL}/assets/icons/arrow.png`}
                        width='317px'
                        height='188px'
                        loading='lazy'
                        alt='flèche vers la droite' 
                        className='carrousel__button__arrow carrousel__button__arrow__right' 
                      />
                    </button>
                    <p className='carrousel__count'>
                      {currentPic + 1} / {picsLength} 
                    </p>
                  </>
                ) : null}
              </div>
            }
          />
        ))}
      </section>
      <Footer />
    </>
  ):(

    < Navigate replace to ="/Error" />

  )
}

export default Card